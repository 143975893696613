<template>
  <div class="ci__ui">
    <div
      class="input-container"
      :class="{
        error: errors.length > 0
      }"
    >
      <input
        type="text"
        v-model="_value"
        maxlength="6"
        placeholder="______"
      >
    </div>

    <p
      v-for="(error, index) in errors"
      :key="index"
      class="ci__ui__error-text"
    >
      {{ $t(error) }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null
    },
    errors: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    _value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    modelValue () {
      this._value = this._value.replace(/[^0-9]/g, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.ci__ui {
  .input-container {
    align-items: center;
    justify-items: center;

    input {
      position: static !important;
      text-align: center;
      font-size: 18px;
      line-height: 100%;
      color: rgb(var(--color-dark));
      letter-spacing: 5px;
      text-transform: uppercase;
    }
  }
}
</style>
