<template>
  <div class="container">
    <div class="container__control">
      <ClosePopUpButton
        class="black"
      />
    </div>

    <div class="container__content">
      <div class="container__content__image">
        <img src="@/static/svg/email-check-icon.svg">
      </div>

      <h3>
        {{ $t('components.ui.pages.pop-up.withdrawal-confirm.withdrawal-confirm') }}
      </h3>

      <p>
        {{ $t('components.ui.pages.pop-up.withdrawal-confirm.code') }}
      </p>

      <CodeInput
        style="margin-bottom: 30px;"
        v-model="code"
        :errors="errors"
      />

      <BlueButton
        :text="$t('components.ui.pages.pop-up.withdrawal-confirm.confirm')"
        :loading="loading"
        :disabled="loading || code.length !== 6"
        @click="submit()"
      />

      <div class="container__content__mb">
        <a
          v-if="seconds === 0"
          @click="resend()"
        >
          {{ $t('components.ui.pages.pop-up.withdrawal-confirm.resend') }}
        </a>

        <a
          v-else
          class="lowercase"
        >
          {{ $t('components.ui.pages.pop-up.withdrawal-confirm.seconds', { seconds: format(seconds) }) }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopUpButton from '@/ui/buttons/ClosePopUpButton.vue'
import CodeInput from '@/ui/inputs/Code.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    ClosePopUpButton,
    CodeInput,
    BlueButton
  },
  data () {
    return {
      loading: false,
      code: '',
      errors: [],

      timer: null,
      seconds: 0
    }
  },
  beforeUnmount () {
    clearInterval(this.timer)
  },
  methods: {
    async submit () {
      this.loading = true
      this.errors = []

      try {
        const formData = new FormData()
        formData.append('code', this.code)

        const resp = await this.$axios.post(
          '/private/withdraw/verify', formData)
        if (resp.status === 200) {
          this.$pop.up({
            name: 'successfully',
            title: this.$t('components.ui.pages.pop-up.withdrawal-confirm.success-title'),
            text: this.$t('components.ui.pages.pop-up.withdrawal-confirm.success-text')
          })
        } else {
          this.errors.push(this.$t('errors.code'))
        }
      } catch (err) {
        this.code = ''
        this.errors.push(this.$t('errors.code'))
        throw new Error(err)
      } finally {
        this.$store.dispatch('fetchBalance', this.$axios)
        this.loading = false
      }
    },
    async resend () {
      this.createTimer()

      try {
        await this.$axios.post(
          '/private/resend-code')
      } catch (err) {
        throw new Error(err)
      }
    },
    createTimer () {
      this.seconds = 60

      this.timer = setInterval(() => {
        if (this.seconds < 1) {
          this.seconds = 0
          clearInterval(this.timer)
        } else {
          this.seconds = this.seconds - 1
        }
      }, 1000)
    },
    format (value) {
      if (value < 10) {
        return `0${value}`
      }

      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  max-width: 430px;
  padding: 10px;
  background: white;
  border-radius: 15px;

  .container__control {
    display: flex;
    justify-content: flex-end;
  }

  .container__content {
    padding: 0 10px 20px 10px;

    .container__content__image {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }

    h3 {
      line-height: 100%;
      color: rgb(var(--color-dark));
      font-family: SemiBold;
      text-align: center;
    }

    p {
      margin: 20px 0;
      font-size: 16px;
      line-height: 150%;
      text-align: center;
      color: rgb(var(--color-dark));
    }

    .container__content__mb {
      margin: 20px 0 0;
      text-align: center;

      a {
        font-size: 14px;
        line-height: 100%;
        color: rgb(var(--primary-color));
        font-family: SemiBold;
        text-transform: uppercase;
        text-decoration: none;
        cursor: pointer;

        &.lowercase {
          text-transform: lowercase;
          cursor: default;

          &:active {
            color: rgb(var(--primary-color));
          }
        }

        &:active {
          color: #202539;
        }
      }
    }
  }
}
</style>
